<template>
  <div>
    <h4 class="mb-4 page-title">Пользователи</h4>
    <div class="widget">
      <div class="filter__container">
        <div class="row mb-3 justify-content-between">
          <div class="col-12 text-right">
            <button class="button_add" @click="addNewUser">Добавить пользователя</button>
          </div>
        </div>
      </div>
      <table class="table table-hover">
        <thead class="table_head">
        <tr>
          <th>логин</th>
          <th>имя</th>
          <th>роль</th>
          <th>описание</th>
          <th>Номер</th>
          <th class="text-center actions">действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in getUserList" :key="user.id">
          <td>{{user.login}}</td>
          <td>{{user.name}}</td>
          <td>{{user.role}}</td>
          <td>{{user.role_title}}</td>
          <td>{{user.phone}}</td>
          <td class="actions_container">
            <router-link v-if="user.role !== 'superadmin'" class="leads_table_action_link btn mr-2 btn-primary"
                         :to="{name: 'UserDetail', params: {id: user.id}}">
              <span class="glyphicon glyphicon-edit"></span>
            </router-link>
            <a v-if="user.role !== 'superadmin'" class="btn mr-2 btn-danger" @click.prevent="deleteRow(user.id, user.name)">
              <span class="fa fa-trash-o delete"></span>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import DeleteModal from "../../components/Modal/DeleteModal";

export default {
  name: "Users.vue",
  computed: {
    ...mapGetters({
      getUserList: 'users/getUsersList'
    })
  },
  methods: {
    ...mapActions({
      fetchUserList: 'users/fetchUserList',
      deleteRecord: 'users/deleteRecord',
    }),
    deleteRow(id, name) {
      this.$modal.show(
          DeleteModal,
          {
            recordId: id,
            recordName: name,
            deleteHandler: this.deleteRecord
          }, { name: 'DeleteModal' }
      )
    },
    addNewUser() {
      this.$router.push({name: 'UserAdd'})
    }
  },
  async created() {
    await this.fetchUserList()
  }
}
</script>

<style lang="scss" scoped>
.btn .glyphicon {
  top: 0 !important;
}
.actions {
  width: 100px;
}
.leads_table_action_link {
  //margin-right: 10px;
  width: 50%;
  text-align: center;
  display: block;
}

.actions {
  max-width: 100px;
}

.actions_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_add {
  display: inline-block;
  vertical-align: middle;
  border: none;
  font-weight: 400;
  background: #3E86CA;
  color: #fff;
  border-radius: 0.5rem;
  padding: 5px 20px;
  font-size: 14px;
  height: 100%;
  transition: .1s;

  &:hover {
    background: #2e6ca7;
    text-decoration: none;
  }
}
</style>
